define("discourse/plugins/discourse-vimeo/discourse/initializers/vimeo", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeVimeo(api) {}
  var _default = _exports.default = {
    name: "discourse-vimeo",
    initialize(container) {
      const siteSettings = container.lookup('site-settings:main');
      const currentUser = container.lookup('current-user:main');
      if (siteSettings.discourse_vimeo_enabled) {
        (0, _pluginApi.withPluginApi)('0.5', initializeVimeo);
      }
    }
  };
});