define("discourse/plugins/discourse-vimeo/lib/discourse-markdown/whitelist-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  function setup(helper) {
    helper.whiteList(['iframe[webkitallowfullscreen]']);
    helper.whiteList(['iframe[mozallowfullscreen]']);
    helper.whiteList(['iframe[allowfullscreen]']);
  }
});